<template>
  <v-container fluid>
    <AError :api="apiDeleteApplication" />
    <transition name="fade">
      <v-alert
        dense
        border="left"
        :icon="alertErrorIcon"
        type="error"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <!-- For duplicate application alert-->
    <transition name="fade" v-if="isApplicationDuplicate">
      <v-alert
        prominent
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
      >
        <v-row align="center">
          <v-col class="grow">
            Duplicate Application Detected, this happens due to multiple entry
            submitted, please check back based on the <b>Company Name</b>
          </v-col>
        </v-row>
      </v-alert>
    </transition>

    <!-- Approve application dialog -->
    <v-dialog v-model="updateStatusDialog" max-width="550">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Approve Application Status
        </v-toolbar>

        <div class="pa-4" v-show="unusualPricing">
          <v-alert dense border="left" type="warning">
            Detected Unusual taxPOD Pricing: {{ applicationDetails.oriPrice }}
          </v-alert>

          Are you sure to update the status to
          <span class="font-weight-bold">{{ updateStatusText }}</span>
        </div>

        <div class="pa-4" v-show="paymentMadeApplication">
          <v-alert dense border="left" type="error">
            This is an application with payment made, but system unable detect
            taxPOD subscription period from Vision, please go
            <b>Edit Details</b> to manually assign 1 company from Vision:
          </v-alert>
        </div>

        <v-card-text class="pt-2">
          Customer Service (optional):
          <v-select
            dense
            filled
            multiple
            chips
            :menu-props="{ closeOnContentClick: true }"
            v-model="customerServiceDetails.customerServiceSelect"
            :items="customerServiceItems"
            :error-messages="errorField.customerServiceSelect"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalUpdateApplicationStatus()"
          >
            Approve
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="updateStatusDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete application dialog -->
    <v-dialog v-model="deleteApplicationDialog" max-width="400">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Delete Application
        </v-toolbar>

        <div class="pa-4">
          Are you sure to delete this application? <br />
          <span class="text-body-2"
            >(*Deleted application can still be restore by admin)</span
          >
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="deleteApplication()"
          >
            Delete
          </v-btn>

          <v-btn
            color="primary lighten-1"
            class="text-light"
            @click="deleteApplicationDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="generalRemarksDialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark
            >General Remarks</v-toolbar
          >
          <div class="text-h6 pa-5">
            <AError :api="apiSubmitGeneralRemarks" />
            <v-textarea
              v-model="otherDetails.generalRemarks"
              label="General Remarks"
              filled
              auto-grow
            >
              {{ otherDetails.generalRemarks }}
            </v-textarea>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="submitGeneralRemarks()">Update</v-btn>
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- View email dialog -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1500"
      v-model="emailDialog"
    >
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>{{
          emailModalDetails.emailModalHeader
        }}</v-toolbar>
        <v-card-text>
          <div class="pa-6">
            <v-row no-gutters>
              <v-alert text>
                <span class="h5">
                  Subject:
                  {{ emailModalDetails.emailModalSubject }}
                </span>
              </v-alert>
            </v-row>
            <v-alert text>
              <v-row no-gutters>
                <div>
                  <div class="d-inline">
                    <span class="h6">To Recipient:</span>
                  </div>
                  <div class="d-inline">
                    <span
                      v-for="(
                        toRecipient, index
                      ) in emailModalDetails.emailModalToRecipient"
                      :key="index"
                      class="font-weight-bold"
                    >
                      {{ toRecipient }};
                    </span>
                  </div>
                </div>
              </v-row>
              <v-row no-gutters>
                <div>
                  <div class="d-inline">
                    <span class="h6">Cc Recipient:</span>
                  </div>
                  <div class="d-inline">
                    <span
                      v-for="(
                        ccRecipient, index
                      ) in emailModalDetails.emailModalCcRecipient"
                      :key="index"
                      class="font-weight-bold"
                    >
                      {{ ccRecipient }};
                    </span>
                  </div>
                </div>
              </v-row>
              <hr />
              <v-row no-gutters>
                <v-col cols="12">
                  <div
                    class="pa-3"
                    v-html="emailModalDetails.emailModalContent"
                  ></div>
                </v-col>
              </v-row>
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="emailDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- View Application Log dialog -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1200"
      v-model="viewLogDialog"
    >
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark
          >Application Status</v-toolbar
        >

        <!-- <v-card flat> -->
        <v-tabs v-model="logTab" dark align-with-title grow>
          <v-tab :key="1" :href="`#tab-1`">
            Application Logs &nbsp;
            <v-icon>mdi-text-box-edit</v-icon>
          </v-tab>
          <v-tab :key="2" :href="`#tab-2`">
            General Logs &nbsp;
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="logTab">
          <v-tab-item :key="1" :value="`tab-1`">
            <v-card-text>
              <v-card>
                <v-simple-table fixed-header dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Performed By</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Scheduled Date</th>
                        <th class="text-left">Created Date</th>
                        <th class="text-left">Last Updated Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(stage, index) in applicationLogs"
                        :key="`${stage.id}-${stage.createdDate}-${index}`"
                      >
                        <td>{{ stage.userUuid }}</td>
                        <td>{{ stage.stageName }}</td>
                        <td>{{ stage.scheduledDate }}</td>
                        <td>{{ stage.createdDate }}</td>
                        <td>{{ stage.updatedDate }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-card-text>
          </v-tab-item>
          <v-tab-item :key="2" :value="`tab-2`">
            <v-card-text>
              <v-card>
                <v-simple-table fixed-header dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Performed By</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Scheduled Date</th>
                        <th class="text-left">Created Date</th>
                        <th class="text-left">Last Updated Date</th>
                      </tr>
                    </thead>
                    <tbody v-if="generalLogs.length > 0">
                      <tr
                        v-for="(stage, index) in generalLogs"
                        :key="`${stage.id}-${stage.createdDate}-${index}`"
                      >
                        <td>{{ stage.userUuid }}</td>
                        <td>{{ stage.stageName }}</td>
                        <td>{{ stage.scheduledDate }}</td>
                        <td>{{ stage.createdDate }}</td>
                        <td>{{ stage.updatedDate }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="10" class="text-center text--disabled">
                          -No Data Available-
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-card> -->

        <v-card-actions class="justify-end">
          <v-btn text @click="viewLogDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add a flag remark dialog -->
    <v-dialog
      v-model="flagDialog"
      max-width="500"
      @click:outside="clearErrorMsg()"
    >
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark
          >Add a Remark</v-toolbar
        >
        <div class="text-h6 pa-5">
          <v-alert
            dense
            border="left"
            icon="mdi-alert"
            type="error"
            class="text-pre-wrap"
            v-if="api.error"
            ><span class="d-block">{{ api.error }}</span></v-alert
          >

          <v-textarea
            v-model="flagRemarks"
            :error-messages="errorField.flagRemarks"
            auto-grow
            filled
            label="Remark"
          >
            {{ flagRemarks }}
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information </v-icon>
                </template>
                Flag case is required to put remarks
              </v-tooltip>
            </template>
          </v-textarea>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="updateFlagAndRemark()"
          >
            {{ flagOrUnflagText }}
          </v-btn>

          <v-btn
            v-if="flagOrUnflagText == 'Unflag'"
            color="blue lighten-2"
            class="text-light"
            @click="updateFlagRemarkOnly()"
          >
            Update Flag Remark
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="closeUpdateFlagAndRemarkDialog()"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Restore trash application dialog -->
    <v-dialog v-model="restoreApplicationDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark
          >Restore Application</v-toolbar
        >

        <div class="pa-5">
          <AError :api="apiRestoreApplication" />
          Are you sure to restore this application?
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            class="text-light"
            @click="restoreTrashApplication()"
          >
            Restore
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="restoreApplicationDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- To handle attachment file action menu-->
    <v-menu
      v-model="showAttachmentAction.menu"
      :position-x="attachmentX"
      :position-y="attachmentY"
      absolute
      offset-y
      rounded="lg"
    >
      <v-list dense>
        <v-list-item
          link
          v-if="showAttachmentAction.view"
          @click.prevent="
            previewOrDownloadAttachment(attachmentPointingItem, 'preview')
          "
        >
          <v-list-item-title>View</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          v-if="showAttachmentAction.download"
          @click.prevent="
            previewOrDownloadAttachment(attachmentPointingItem, 'download')
          "
        >
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          v-if="showAttachmentAction.email"
          @click="getEmailContent(attachmentPointingItem)"
        >
          <v-list-item-title>Email</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Make duplicated application valid dialog -->
    <v-dialog v-model="proceedDuplicateApplicationDialog" max-width="400">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark
          >Move Application to Tab 1 (Application Submitted)</v-toolbar
        >

        <div class="pa-4">
          <v-alert
            dense
            border="left"
            icon="mdi-alert"
            type="error"
            class="text-pre-wrap"
            v-if="apiProceedDuplicateApplication.error"
            ><span class="d-block">{{
              apiProceedDuplicateApplication.error
            }}</span></v-alert
          >
          This action <b>WILL NOT</b> approve application. <br />
          Are you sure to proceed this application?
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="success lighten-1"
            class="text-light"
            @click="moveApplicationToSubmitted()"
          >
            Proceed
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="proceedDuplicateApplicationDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-toolbar>
        <v-btn
          v-if="this.applicationDetails.stageId != 26"
          icon
          class="hidden-xs-only"
          @click="goToBack()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn
          v-if="this.applicationDetails.stageId == 26"
          icon
          class="hidden-xs-only"
          @click="goToBackApPage()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center">
            {{ model.view.name }}
            &nbsp;
            <v-alert
              :color="alertColor"
              dense
              dark
              class="mb-0"
              type="info"
              border="left"
              >{{ pendingAction() }}</v-alert
            >
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          class="hidden-xs-only"
          :color="flaggedCase.color"
          @click="openUpdateFlagModal()"
          v-if="displayDeletedApplication"
        >
          <v-icon dark>
            {{ flaggedCase.mdiIcon }}
          </v-icon>
        </v-btn>

        <span v-if="rolesAllowed && displayDeletedApplication">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="hidden-xs-only"
                color="warning lighten-1"
                dark
                @click.stop="updateStatusDialog = true"
              >
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-check-decagram-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Approve Application</span>
          </v-tooltip>
        </span>

        <span v-if="nextActionDisplay && displayDeletedApplication">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="hidden-xs-only"
                color="blue-grey"
                @click.stop="nextAction()"
              >
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-send-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Next Action</span>
          </v-tooltip>
        </span>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="hidden-xs-only"
              color="primary"
              @click="viewLogDialog = true"
            >
              <v-icon dark v-bind="attrs" v-on="on">
                mdi-clipboard-text-clock-outline
              </v-icon>
            </v-btn>
          </template>
          <span>View Log</span>
        </v-tooltip>

        <v-tooltip top v-if="!displayDeletedApplication">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="hidden-xs-only"
              color="teal"
              @click="restoreApplicationDialog = true"
            >
              <v-icon dark v-bind="attrs" v-on="on">
                mdi-delete-restore
              </v-icon>
            </v-btn>
          </template>
          <span>Restore Application</span>
        </v-tooltip>

        <v-menu
          offset-y
          :rounded="'lg'"
          transition="slide-y-transition"
          :max-height="'300'"
          v-if="displayDeletedApplication"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(action, index) in actions"
              :key="index"
              dense
              link
              @click="redirectForm(action)"
            >
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </div>

    <v-card id="fab-speed-dial" v-if="!loadingDialog" tile>
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-row>
          <div class="col-md-6">
            <v-col>
              <!-- Company Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    COMPANY DETAILS
                    <v-icon color="white"> mdi-office-building-outline </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Company Name (Filled By Applicant):
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            style="text-transform: none !important"
                            @click="copyContent(companyDetails.oriCompanyName)"
                          >
                            {{ companyDetails.oriCompanyName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company Address: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.companyAddress)"
                          >
                            {{ companyDetails.companyAddress }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company SSM No.: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.companySsmNo)"
                          >
                            {{ companyDetails.companySsmNo }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Company Name (Vision):
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text text--disabled"
                            active-class="no-active"
                            @click="copyContent(companyDetails.companyNameOnly)"
                          >
                            {{ companyDetails.companyNameOnly }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.picName)"
                          >
                            <span v-html="companyDetails.picNameDisplay">
                            </span>
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Email: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            style="text-transform: none !important"
                            @click="copyContent(companyDetails.picEmail)"
                          >
                            <span v-html="companyDetails.picEmailDisplay">
                            </span>
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Client PIC Contact No:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.picContactNo)"
                          >
                            <span v-html="companyDetails.picContactNoDisplay">
                            </span>
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.bankName)"
                          >
                            {{ companyDetails.bankName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Account No: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(companyDetails.bankAccNo)"
                          >
                            {{ companyDetails.bankAccNo }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Bank Account Holder Name:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(companyDetails.bankAccHolderName)
                            "
                          >
                            {{ companyDetails.bankAccHolderName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Salesperson Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    SALESPERSON DETAILS
                    <v-icon color="white"> mdi-briefcase-account </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Salesperson Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(salespersonDetails.salespersonName)
                            "
                          >
                            {{ salespersonDetails.salespersonName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Salesperson Email:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(salespersonDetails.salespersonEmail)
                            "
                          >
                            {{ salespersonDetails.salespersonEmail }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2"
                          >Customer Service Name:</span
                        >
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(
                                customerServiceDetails.customerServiceName
                              )
                            "
                          >
                            {{ customerServiceDetails.customerServiceName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Enrollment Details -->
              <v-expansion-panel v-if="showEnrollmentDetails">
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    ENROLLMENT DETAILS
                    <v-icon color="white"> mdi-form-select </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-carousel
                      v-model="enrollmentCarousel"
                      :show-arrows="false"
                      height="250px"
                    >
                      <v-carousel-item
                        v-for="(
                          enrollment, i
                        ) in enrollmentDetails.enrollmentLists"
                        :key="i"
                      >
                        <v-row
                          no-gutters
                          class="d-flex align-items-center pb-2"
                        >
                          <v-col cols="4">
                            <span class="text-subtitle-2"
                              >Enrollment First Name:</span
                            >
                          </v-col>
                          <v-col cols="8">
                            <v-btn-toggle>
                              <v-btn
                                class="btn-wrap-text"
                                active-class="no-active"
                                @click="
                                  copyContent(enrollment.firstNameAccEnrollment)
                                "
                              >
                                {{ enrollment.firstNameAccEnrollment }}
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                          class="d-flex align-items-center pb-2"
                        >
                          <v-col cols="4">
                            <span class="text-subtitle-2"
                              >Enrollment Last Name:</span
                            >
                          </v-col>
                          <v-col cols="8">
                            <v-btn-toggle>
                              <v-btn
                                class="btn-wrap-text"
                                active-class="no-active"
                                @click="
                                  copyContent(enrollment.lastNameAccEnrollment)
                                "
                              >
                                {{ enrollment.lastNameAccEnrollment }}
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                          class="d-flex align-items-center pb-2"
                        >
                          <v-col cols="4">
                            <span class="text-subtitle-2"
                              >Enrollment Contact No:</span
                            >
                          </v-col>
                          <v-col cols="8">
                            <v-btn-toggle>
                              <v-btn
                                class="btn-wrap-text"
                                active-class="no-active"
                                @click="
                                  copyContent(enrollment.contactNoAccEnrollment)
                                "
                              >
                                {{ enrollment.contactNoAccEnrollment }}
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="d-flex align-items-center">
                          <v-col cols="4">
                            <span class="text-subtitle-2"
                              >Enrollment Email:</span
                            >
                          </v-col>
                          <v-col cols="8">
                            <v-btn-toggle>
                              <v-btn
                                class="btn-wrap-text"
                                active-class="no-active"
                                @click="
                                  copyContent(enrollment.emailAccEnrollment)
                                "
                              >
                                {{ enrollment.emailAccEnrollment }}
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Attachment Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    ATTACHMENT DETAILS
                    <v-icon color="white"> mdi-attachment </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-treeview
                      dense
                      hoverable
                      open-all
                      :items="attachmentTreeview"
                      :transition="true"
                    >
                      <template v-slot:prepend="{ item, open, leaf }">
                        <div
                          class="d-flex justify-content-center1"
                          @contextmenu="showFileActionList($event, item)"
                          :style="{ width: leaf ? '700px' : 'auto' }"
                          style="cursor: pointer"
                          @dblclick="
                            previewOrDownloadAttachment(item, 'preview')
                          "
                        >
                          <v-icon v-if="!leaf" color="#f2b720">
                            {{ open ? "mdi-folder-open" : "mdi-folder" }}
                          </v-icon>
                          <v-icon
                            v-else
                            :color="fileExtension[item.fileExtension].color"
                          >
                            {{ fileExtension[item.fileExtension].icon }}
                          </v-icon>
                          <span
                            class="text-body-2 text-uppercase d-flex align-items-center"
                          >
                            {{ item.fileName }}
                          </span>
                        </div>
                      </template>
                    </v-treeview>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
          <div class="col-md-6">
            <v-col>
              <!-- Application Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    APPLICATION DETAILS
                    <v-icon color="white">
                      mdi-application-edit-outline
                    </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Current Stage:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-chip
                          color="blue lighten-2"
                          link
                          label
                          class="text--primary"
                        >
                          <span v-html="applicationDetails.stageName"></span>
                        </v-chip>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2"
                          >taxPOD Subscription Period:</span
                        >
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(taxpodSubscriptionPeriod)"
                          >
                            {{ taxpodSubscriptionPeriod }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">taxPOD Type:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(
                                applicationDetails.applicationTaxpodType
                              )
                            "
                          >
                            {{ applicationDetails.applicationTaxpodType }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Payment Channel:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="
                              copyContent(applicationDetails.paymentChannel)
                            "
                          >
                            {{ applicationDetails.paymentChannel }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Payment Mode:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(applicationDetails.paymentMode)"
                          >
                            {{ applicationDetails.paymentMode }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Programme Name:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            style="text-transform: none !important"
                            @click="
                              copyContent(applicationDetails.programmeName)
                            "
                          >
                            {{ applicationDetails.programmeName }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Refund Type:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            class="btn-wrap-text"
                            active-class="no-active"
                            @click="copyContent(applicationDetails.refundType)"
                          >
                            {{ applicationDetails.refundType }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Claim Amount:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="text--disabled">
                          <v-chip
                            class="blue lighten-2 text--primary"
                            label
                            link
                          >
                            {{ applicationDetails.totalPrice }}
                          </v-chip>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      class="d-flex align-items-center pb-2"
                      v-show="applicationDetails.oriPrice"
                    >
                      <v-col cols="4">
                        <span class="text-subtitle-2"
                          >Claim Amount (Filled In By Applicant):</span
                        >
                      </v-col>
                      <v-col cols="8">
                        <span class="text--disabled">
                          <v-chip
                            class="blue lighten-2 text--primary"
                            label
                            link
                          >
                            {{ applicationDetails.oriPrice }}
                          </v-chip>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">Is Payment Made:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-chip
                          class="text--primary"
                          :color="
                            applicationDetails.isPaymentMade
                              ? 'green lighten-2'
                              : 'red lighten-2'
                          "
                          label
                          link
                        >
                          {{ applicationDetails.applicationIsPaymentMade }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center pb-2">
                      <v-col cols="4">
                        <span class="text-subtitle-2">To Refund:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-chip
                          class="text--primary"
                          :color="
                            applicationDetails.isPaymentMade
                              ? 'green lighten-2'
                              : 'red lighten-2'
                          "
                          label
                          link
                        >
                          {{ applicationDetails.toRefund }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex align-items-center">
                      <v-col cols="4">
                        <span class="text-subtitle-2">taxPOD Activation:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-chip
                          class="text--primary"
                          :color="taxpodActivationTickColor"
                          label
                          link
                        >
                          {{ applicationDetails.taxpodActivation }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Statement Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    STATEMENT DETAILS
                    <v-icon color="white"> mdi-folder-information </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row
                      no-gutters
                      class="d-flex align-items-center pb-2"
                      v-for="(statement, index) in formattedStatementDetails"
                      :key="'formattedStatementDetails' + index"
                    >
                      <v-col cols="4">
                        <span class="text-subtitle-2">{{ index }}:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-btn-toggle>
                          <v-btn
                            style="text-transform: none !important"
                            active-class="no-active"
                            class="btn-wrap-text"
                            @click="copyContent(statement)"
                            >{{ statement }}</v-btn
                          >
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Other Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    OTHER DETAILS
                    <v-icon color="white">
                      mdi-dots-horizontal-circle-outline
                    </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="2">
                        <span class="text-subtitle-2">Remarks:</span>
                      </v-col>
                      <v-col cols="10">
                        <v-card v-if="checkRemarks">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr class="grey lighten-2">
                                  <th class="text-left">Stage</th>
                                  <th class="text-left">Remark</th>
                                  <th class="text-left">
                                    Remark By/Last Updated By
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="remark in otherDetails.remarks"
                                  :key="remark.id"
                                  :class="{
                                    'red lighten-2':
                                      remark.id === 'GENERAL_REMARKS',
                                  }"
                                >
                                  <td>{{ remark.stageName }}</td>
                                  <td>{{ remark.remarks }}</td>
                                  <td>{{ remark.remarkBy }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>
                        <div v-else>-</div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
        </v-row>
      </v-expansion-panels>

      <v-speed-dial
        v-model="fab"
        :bottom="bottom"
        :right="right"
        :direction="direction"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-note </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="grey" @click="generalRemarksDialog = true">
          <v-icon>mdi-note-edit-outline</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card>

    <v-skeleton-loader
      v-if="loadingDialog"
      :loading="loadingDialog"
      class="mx-auto rounded-0"
      type="table-heading, card, table-heading, card, table-heading, card, table-heading, card"
    >
      <v-dialog v-model="loadingDialogBar" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Loading
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-skeleton-loader>

    <HrdcLoader :loadingDialog="atchLoadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    formattedStatementDetails() {
      const formatted = {};
      for (const key in this.statementDetails) {
        switch (key) {
          case "manualInvoiceToHrdcNo":
            if (this.applicationDetails._refundType == 2) {
              formatted["Manual Invoice to HRDC (70%)"] =
                this.statementDetails[key];
              break;
            }

            formatted[this.camelCaseToNormalCase(key)] =
              this.statementDetails[key];
            break;
          case "manualInvoiceToHrdcNo2":
            if (this.applicationDetails._refundType == 2) {
              formatted["Manual Invoice to HRDC (30%)"] =
                this.statementDetails[key];
              break;
            }
            break;
          default:
            formatted[this.camelCaseToNormalCase(key)] =
              this.statementDetails[key];
            break;
        }
      }
      return formatted;
    },
    showEnrollmentDetails() {
      return (
        !this.applicationDetails.isPaymentMade &&
        this.applicationDetails.taxpodType == 1
      );
    },
    paymentMadeApplication() {
      if (
        this.applicationDetails.isPaymentMade &&
        !this.companyDetails.oldCompanyId
      ) {
        return true;
      }

      return false;
    },
    taxpodSubscriptionPeriod() {
      if (
        this.applicationDetails.subscriptionStartDate &&
        this.applicationDetails.subscriptionEndDate
      ) {
        return `${this.applicationDetails.subscriptionStartDate} to ${this.applicationDetails.subscriptionEndDate}`;
      }

      return "-";
    },
    checkRemarks() {
      if (this.otherDetails.remarks.length > 0) {
        return true;
      }

      return false;
    },
    flaggedCase() {
      let color = null;
      let mdiIcon = "mdi-flag-outline";
      this.alertError = this.alertError.filter(
        (error) => error !== "This case is being flagged"
      );
      if (this.applicationDetails.isFlag) {
        color = "error";
        mdiIcon = "mdi-flag";

        this.alertError.push("This case is being flagged");
        this.alertErrorIcon = "mdi-flag";
      }

      return {
        color,
        mdiIcon,
      };
    },
    isApplicationDuplicate() {
      return this.applicationDetails.isApplicationDuplicate;
    },
    displayDeletedApplication() {
      if (this.applicationDetails.isApplicationDeleted) {
        return false;
      }

      return true;
    },
  }),
  props: ["params"],
  data: () => ({
    apiGetApplicationDetails: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    apiGetApplicationStages: new Api(),
    apiGetEmailDetails: new Api(),
    apiGetAttachmentBlob: new Api(),
    apiDeleteApplication: new Api(),
    apiRestoreApplication: new Api(),
    apiSubmitGeneralRemarks: new Api(),
    apiProceedDuplicateApplication: new Api(),
    apiSubmit: new Api(),
    api: new Api(),
    model: new Model(),
    hrdcData: new HrdcData(),
    service: new Service(),
    applicationLogs: [],
    generalLogs: [],
    taxpodActivationTickColor: "green lighten-2",
    stages: [],
    allStages: [],
    loadingDialog: false,
    loadingDialogBar: false,
    panel: [0, 1, 2, 3, 4, 5, 6, 7],
    errorField: {
      updateStageId: null,
      customerServiceSelect: null,
      flagRemarks: null,
    },
    companyDetails: {
      oriCompanyName: null,
      companyNameOnly: null,
      companyName: null,
      companyAddress: null,
      companySsmNo: null,
      picName: null,
      picContactNo: null,
      bankName: null,
      bankAccNo: null,
      bankAccHolderName: null,
      oldCompanyId: null,
      picNameDisplay: "",
      picEmailDisplay: "",
      picContactNoDisplay: "",
    },
    salespersonDetails: {
      salespersonName: null,
      salespersonEmail: null,
      salespersonContactNo: null,
    },
    customerServiceDetails: {
      customerServiceName: "-",
      customerServiceSelect: [],
    },
    enrollmentDetails: {
      firstNameAccEnrollment: null,
      lastNameAccEnrollment: null,
      contactNoAccEnrollment: null,
      emailAccEnrollment: null,
      dynamicEnrollmentDetails: null,
      enrollmentLists: [],
    },
    applicationDetails: {
      stageId: null,
      updateStageId: null,
      subscriptionStartDate: null,
      subscriptionEndDate: null,
      applicationTaxpodType: null,
      stageName: null,
      oriPrice: null,
      subtotalPrice: null,
      totalPrice: null,
      oriTotalPrice: null,
      taxPrice: null,
      taxRate: null,
      isPaymentMade: null,
      paymentChannel: null,
      toRefund: null,
      taxpodActivation: null,
      paymentMode: null,
      programmeName: null,
      refundType: null,
      _refundType: null,
      isFlag: null,
      isApplicationDuplicate: false,
      isApplicationDeleted: false,
    },
    attachmentDetails: [],
    taxpodAttachment: [],
    hrdcAttachment: [],
    financeAttachment: [],
    othersAttachment: [],
    attachmentTreeview: [
      {
        id: 1,
        name: "HRDC",
        children: null,
      },
      {
        id: 2,
        name: "taxPOD",
        children: null,
      },
      {
        id: 3,
        name: "Finance",
        children: null,
      },
      {
        id: 4,
        name: "Others",
        children: null,
      },
    ],
    statementDetails: {
      grantApprovalNo: "-",
      employerCodeEtris: "-",
      manualInvoiceToHrdcNo: "-",
      manualInvoiceToHrdcNo2: "-",
      clientNoXero: "-",
      claimId: "-",
      creditNote: "-",
      autoGeneratedInvoice: "-",
    },
    otherDetails: {
      remarks: [],
      generalRemarks: null,
      flagRemarks: null,
    },
    emailModalDetails: {
      emailModalHeader: null,
      emailModalSubject: null,
      emailModalContent: null,
      emailModalToRecipient: null,
      emailModalCcRecipient: null,
    },
    updateStatusBtn: null,
    updateStatusText: null,
    rolesAllowed: null,
    updateStatusDialog: false,
    deleteApplicationDialog: false,
    generalRemarksDialog: false,
    proceedDuplicateApplicationDialog: false,
    actions: null,
    stageCountable: [],
    customerServiceItems: [],
    unusualPricing: false,
    alertError: [],
    alertErrorIcon: "mdi-alert",
    duplicateApplicationAlertError: [],
    direction: "top",
    fab: false,
    tabs: null,
    right: true,
    bottom: true,
    transition: "slide-y-reverse-transition",
    nextActionDisplay: false,
    emailDialog: false,
    viewLogDialog: false,
    restoreApplicationDialog: false,
    alertColor: null,
    flagDialog: false,
    flagRemarks: null,
    flagOrUnflagText: "Flag",
    enrollmentCarousel: 0,
    logTab: "tab-1",
    showAttachmentAction: {
      menu: false,
      view: false,
      download: false,
      email: false,
    },
    attachmentX: 0,
    attachmentY: 0,
    attachmentPointingItem: null,
    fileExtension: {
      pdf: {
        icon: "mdi-file-document-outline",
        color: "red lighten-2",
      },
      png: {
        icon: "mdi-image",
        color: "primary",
      },
      jpg: {
        icon: "mdi-image",
        color: "primary",
      },
      jpeg: {
        icon: "mdi-image",
        color: "primary",
      },
      email: {
        icon: "mdi-email-outline",
        color: "green",
      },
    },
    atchLoadingDialog: false,
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.allStages = await this.hrdcData.allStages;
    this.stageCountable.push(
      this.hrdcData.stagePriority.quotationDateScheduled,
      this.hrdcData.stagePriority.subscriptionDateScheduled,
      this.hrdcData.stagePriority.sentJd14FormToClient,
      this.hrdcData.stagePriority.sentT3FormToClient,
      this.hrdcData.stagePriority.sentConfirmationEmailToClient
    );

    this.hrdcData.rolesAssignation(this.auth.roles);
    this.alertColor = this.hrdcData.HRDC_BLUE_THEME_COLOR;

    // Set actions.
    this.actions = this.hrdcData.actions;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
          type: "MULTIPLE_ENROLLMENT_DETAILS",
        },
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
      apiGetApplicationStages: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-stages`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiGetEmailDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-email-details`,
        method: "post",
      },
      apiGetAttachmentBlob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-blob`,
      },
      apiDeleteApplication: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/delete-application`,
        method: "delete",
      },
      apiRestoreApplication: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/restore-application`,
        method: "post",
      },
      apiSubmitGeneralRemarks: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/general_info/create-general-remarks`,
        method: "post",
      },
      apiProceedDuplicateApplication: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/proceed-duplicate-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetApplicationDetails.setCallbackCompleted(async (response) => {
      try {
        const datas = await response.data;

        const { applicationLog } = response;

        if (!response.status) {
          this.$store.commit("assignDeveloperData", {
            paginationPageNo: this.$route.query.currentPageNo,
          });

          this.$router.push({
            name: "HrdcApplication",
            query: {
              viewId: this.$store.state.hrdc.data.viewId,
            },
          });
          this.$store.dispatch("showMessage", {
            message: datas,
            messageType: "error",
            timeout: 2000,
          });
          return;
        }

        // Just take first row to display basic info.
        const data = response.data[0];

        // To alert admin if detected similar company name.
        const similarCompanyName = response.companyNamesArray;
        if (similarCompanyName) {
          for (const sCompany of similarCompanyName) {
            this.duplicateApplicationAlertError.push({
              title: sCompany.companyName,
              value: sCompany,
            });
          }
        }

        // Company details.
        this.companyDetails.oriCompanyName = data.oriCompanyName.toUpperCase();
        this.companyDetails.companyNameOnly = data.oldCompanyName.toUpperCase();
        this.companyDetails.companyName = `${data.oldCompanyName.toUpperCase()} (${
          data.oldSsmNo ?? "No SSM No."
        })`;
        this.companyDetails.companyAddress =
          data.oldAddress.toUpperCase() ?? "-";
        this.companyDetails.companySsmNo = data.oldSsmNo ?? "No SSM No.";

        const picNameDisplay = response.companyPicData
          .map((item) => item.picName)
          .filter((item) => item !== null);

        const picName = picNameDisplay.join(",");

        for (const name of picNameDisplay) {
          this.companyDetails.picNameDisplay += `${name} <br/>`;
        }

        const picEmailDisplay = response.companyPicData
          .map((item) => item.picEmail)
          .filter((item) => item !== null);

        const picEmail = picEmailDisplay.join(",");

        for (const email of picEmailDisplay) {
          this.companyDetails.picEmailDisplay += `${email} <br/>`;
        }

        const picContactNoDisplay = response.companyPicData
          .map((item) => item.picContactNo)
          .filter((item) => item !== null);

        const picContactNo = picContactNoDisplay.join(",");

        for (const contactNo of picContactNoDisplay) {
          this.companyDetails.picContactNoDisplay += `${contactNo} <br/>`;
        }

        this.companyDetails.picName = picName ? picName : "-";
        this.companyDetails.picEmail = picEmail ? picEmail : "-";
        this.companyDetails.picContactNo = picContactNo ? picContactNo : "-";
        this.companyDetails.bankName = data.bankUuid || "-";
        this.companyDetails.bankAccNo = data.oldBankAccount || "-";
        this.companyDetails.bankAccHolderName =
          data.oldBankAccountHolderName || "-";

        if (data.paymentChannel == "2") {
          this.companyDetails.bankName = "-";
          this.companyDetails.bankAccNo = "-";
          this.companyDetails.bankAccHolderName = "-";
        }

        this.companyDetails.oldCompanyId = data.oldCompanyId;

        // Salesperson details.
        this.salespersonDetails.salespersonName = data.salesperson.name ?? "-";
        this.salespersonDetails.salespersonEmail =
          data.salesperson.email ?? "-";
        this.salespersonDetails.salespersonContactNo =
          data.salesperson.mobile ?? "-";

        // Customer Service details.
        if (response.applicationCs.length > 0) {
          for (const cs of response.applicationCs) {
            this.customerServiceDetails.customerServiceSelect.push(cs.csUuid);
            if ("csName" in cs) {
              this.customerServiceDetails.customerServiceName =
                response.applicationCs.map((item) => item.csName).join(",");
              break;
            }
          }
        }

        if (response.csData) {
          for (const cs of response.csData) {
            this.customerServiceItems.push({
              text: cs.email,
              value: cs.uuid,
            });
          }
        }

        // Enrollment details.
        const initialEnrollmentDetails = [];
        this.enrollmentDetails.firstNameAccEnrollment =
          data.firstNameAccEnrollment ?? "-";
        this.enrollmentDetails.lastNameAccEnrollment =
          data.lastNameAccEnrollment ?? "-";
        this.enrollmentDetails.contactNoAccEnrollment =
          data.contactNoAccEnrollment ?? "-";
        this.enrollmentDetails.emailAccEnrollment =
          data.emailAccEnrollment ?? "-";

        initialEnrollmentDetails.push({
          firstNameAccEnrollment: this.enrollmentDetails.firstNameAccEnrollment,
          lastNameAccEnrollment: this.enrollmentDetails.lastNameAccEnrollment,
          contactNoAccEnrollment: this.enrollmentDetails.contactNoAccEnrollment,
          emailAccEnrollment: this.enrollmentDetails.emailAccEnrollment,
        });

        // Dynamic enrollment details.
        if (response.dynamicEnrollmentDetails.length > 0) {
          this.enrollmentDetails.dynamicEnrollmentDetails =
            response.dynamicEnrollmentDetails;

          this.enrollmentDetails.enrollmentLists =
            initialEnrollmentDetails.concat(response.dynamicEnrollmentDetails);
        } else {
          this.enrollmentDetails.enrollmentLists = initialEnrollmentDetails;
        }

        // Application details.
        this.applicationDetails.stageId = data.stageId;
        this.applicationDetails.subscriptionStartDate =
          data.subscriptionStartDate &&
          data.subscriptionStartDate !== "Invalid date"
            ? data.subscriptionStartDate
            : null;
        this.applicationDetails.subscriptionEndDate =
          data.subscriptionEndDate &&
          data.subscriptionEndDate !== "Invalid date"
            ? data.subscriptionEndDate
            : null;
        this.applicationDetails.stageName = this.castStageName({
          stageName: data.stageName,
          stageNameSlug: data.nameSlug,
          applicationLog,
          grantApprovalNo: data.grantApprovalNo,
        });
        this.applicationDetails.taxpodType = data.taxpodType;
        this.applicationDetails.applicationTaxpodType =
          data.taxpodType == 1 ? "New Sale" : "Renewal";

        // Check unusual pricing filled by applicant.
        if (!this.hrdcData.getTaxpodBasePricing(data.totalPrice)) {
          this.unusualPricing = true;
        }

        this.applicationDetails.oriPrice = data.oriPrice;
        this.applicationDetails.subtotalPrice = `RM ${this.formatNumber(
          data.subtotalPrice
        )}`;
        this.applicationDetails.totalPrice = `RM ${this.formatNumber(
          data.totalPrice
        )}`;
        this.applicationDetails.oriTotalPrice = data.totalPrice;
        this.applicationDetails.taxPrice = `RM ${this.formatNumber(
          data.taxPrice
        )}`;

        this.applicationDetails.taxRate = data.taxRate;
        this.applicationDetails.isPaymentMade = data.isPaymentMade;
        this.applicationDetails.paymentChannel = data.isPaymentMade
          ? "Pay & Refund"
          : "Claim Basis";
        this.applicationDetails.applicationIsPaymentMade = data.isPaymentMade
          ? "Yes"
          : "No";
        this.applicationDetails.toRefund = data.isPaymentMade ? "Yes" : "No";

        let taxpodActivation = null;
        if (data.isPaymentMade) {
          taxpodActivation = "Yes";
        } else {
          if (
            this.hrdcData.stageIndex[data.stageId] >
            this.hrdcData.stageSlug.taxpodAccountActivation
          ) {
            taxpodActivation = "Yes";
          } else {
            taxpodActivation = "No";
            this.taxpodActivationTickColor = "red lighten-2";
          }
        }

        this.applicationDetails.taxpodActivation = taxpodActivation;

        this.applicationDetails.paymentMode =
          data.paymentMode !== null
            ? data.paymentMode +
              (data.paymentMode == "Other" ? ` - ${data.otherPaymentMode}` : ``)
            : "-";

        this.applicationDetails.programmeName = data.programmeName;
        this.applicationDetails._refundType = data.refundType;
        if (data.refundType == 1) {
          this.applicationDetails.refundType = "Normal";
        } else {
          this.applicationDetails.refundType = `70% (RM ${data.seventyPercentPrice.toFixed(
            2
          )}) / 30% (RM ${data.thirtyPercentPrice.toFixed(2)})`;
        }

        this.applicationDetails.isFlag = data.isFlag;
        this.applicationDetails.isApplicationDuplicate =
          data.isApplicationDuplicate;
        this.applicationDetails.isApplicationDeleted =
          data.applicationTimestampDeleted;

        // Statement details.
        this.statementDetails.grantApprovalNo = data.grantApprovalNo ?? "-";
        this.statementDetails.employerCodeEtris = data.employerCodeEtris ?? "-";
        this.statementDetails.manualInvoiceToHrdcNo =
          data.manualInvoiceToHrdc ?? "-";
        this.statementDetails.manualInvoiceToHrdcNo2 =
          data.manualInvoiceToHrdc2 ?? "-";
        this.statementDetails.clientNoXero = data.clientNoXero ?? "-";
        this.statementDetails.claimId = data.claimId ?? "-";
        this.statementDetails.creditNote = data.creditNoteNo ?? "-";
        this.statementDetails.autoGeneratedInvoice =
          data.autoGeneratedTaxpodInvoiceNo ?? "-";
        this.statementDetails.stripeRef = data.stripeRef ?? "-";

        const formatted = {};
        for (const key in this.statementDetails) {
          switch (key) {
            case "manualInvoiceToHrdcNo":
              if (this.applicationDetails._refundType == 2) {
                formatted["Manual Invoice to HRDC (70%)"] =
                  this.statementDetails[key];
                break;
              }

              formatted[this.camelCaseToNormalCase(key)] =
                this.statementDetails[key];
              break;
            case "manualInvoiceToHrdcNo2":
              if (this.applicationDetails._refundType == 2) {
                formatted["Manual Invoice to HRDC (30%)"] =
                  this.statementDetails[key];
                break;
              }
              break;
            default:
              formatted[this.camelCaseToNormalCase(key)] =
                this.statementDetails[key];
              break;
          }
        }

        // Attachment details.
        let lastSentEmailIndex = null;
        let paymentAdviceCount = 1;
        let taxpodInvoiceCount = 1;
        let creditNoteCount = 1;

        const countOfPaymentAdviceToClient = datas.filter(
          (item) =>
            item.fileStageId ==
            Number(this.hrdcData.stagePriority.emailPaymentAdviceToClient)
        );

        const countOfTaxpodInvoice = datas.filter(
          (item) =>
            item.fileStageId ==
            Number(this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice)
        );

        for (let index = 0; index < datas.length; index++) {
          const item = datas[index];

          let fileName = item.fileName;
          let fileNameSlug = this.titleCaseToUppercase(item.fileName);
          let downloadFileName = null;

          if (
            [
              this.hrdcData.stageSlug.sentJd14FormToClient,
              this.hrdcData.stageSlug.sentT3FormToClient,
              this.hrdcData.stageSlug.sentConfirmationEmailToClient,
            ].includes(this.hrdcData.stageIndex[item.fileStageId])
          ) {
            // Set different file name if is send email to client.
            fileName = `${item.fileName} @ ${moment(
              item.fileTimestampCreated
            ).format("YYYY-MM-DD")}`;

            // Increase index if got resend of email.
            if (fileNameSlug == "SENT_CONFIRMATION_EMAIL_TO_CLIENT") {
              lastSentEmailIndex = index;
            }
          }

          switch (fileNameSlug) {
            case "APPLICATION_SUBMITTED":
              if (item.paymentChannel == 2) {
                continue; // Continue the loop if Claim Basis (no screenshot require)
              }
              fileName = "Screenshot of Bank Account Details for Refund";
              break;
            case "MANUAL_INVOICE_TO_HRDC":
              if (item.refundType == 2) {
                const manualInvoiceExists = this.attachmentDetails.some(
                  (item) => item.fileNameSlug == fileNameSlug
                );
                if (manualInvoiceExists) {
                  fileName = "HRDC Invoice (30%)";
                } else {
                  fileName = "HRDC Invoice (70%)";
                }
              } else {
                fileName = "HRDC Invoice";
              }

              downloadFileName = this.statementDetails.manualInvoiceToHrdcNo;
              break;
            case "CLIENT_REVERT_JD14_FORM":
              fileName = "JD14 Completed";
              downloadFileName = "JD14";
              break;
            case "CLIENT_REVERT_T3_FORM":
              fileName = "T3 Completed";
              downloadFileName = "T3";
              break;
            case "REFUND_EMAIL_AR_TO_AP":
              fileName = "Refund Email AR to AP (Compiled PDF)";
              break;

            case "EMAIL_PAYMENT_ADVICE_TO_CLIENT":
              if (countOfPaymentAdviceToClient.length == 1) {
                fileName = "Payment Advice to Client";
              } else {
                fileName = `Payment Advice to Client - ${paymentAdviceCount}`;
                paymentAdviceCount++;
              }
              break;

            case "AUTO_GENERATED_TAXPOD_INVOICE":
              if (countOfTaxpodInvoice.length == 1) {
                fileName = "Client/taxPOD Invoice";
              } else {
                fileName = `Client/taxPOD Invoice - ${taxpodInvoiceCount}`;
                taxpodInvoiceCount++;
              }
              break;

            case "CREDIT_NOTE_GENERATED":
              if (countOfTaxpodInvoice.length == 1) {
                fileName = "Credit Note";
              } else {
                fileName = `Credit Note - ${creditNoteCount}`;
                creditNoteCount++;
              }
              break;
          }

          let tmpAttachmentObj = {
            attachmentUuid: item.attachmentUuid,
            fileName,
            fileNameSlug,
            fileUrl: item.fileUrl,
            applicationUuid: item.applicationUuid,
            fileStageId: item.fileStageId,
            azureStorage: true,
            showView: true,
            showDownload: true,
            downloadFileName,
            fileExtension: item.fileUrl
              ? this.getFileExtension(item.fileUrl)
              : null,
          };

          if (
            tmpAttachmentObj.fileName !== null &&
            tmpAttachmentObj.fileUrl !== null
          ) {
            this.attachmentDetails.push(tmpAttachmentObj);

            let attachmentDuplicateCheck = null;

            switch (item.fileStageId) {
              // taxPOD related docs.
              case this.hrdcData.stagePriority.applicationSubmitted:
              case this.hrdcData.stagePriority.manualInvoiceToHrdc:
              case this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice:
              case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
              case this.hrdcData.stagePriority.emailPaymentAdviceToClient:
              case this.hrdcData.stagePriority.paymentAdviceFromClient:
                this.taxpodAttachment.push(tmpAttachmentObj);
                break;

              // HRDC related docs.
              case this.hrdcData.stagePriority.sentConfirmationEmailToClient:
              case this.hrdcData.stagePriority.sentJd14FormToClient:
              case this.hrdcData.stagePriority.sentT3FormToClient:
              case this.hrdcData.stagePriority.clientRevertJd14Form:
              case this.hrdcData.stagePriority.clientRevertT3Form:
              case this.hrdcData.stagePriority.hrdcGrantEmail:
                this.hrdcAttachment.push(tmpAttachmentObj);
                break;

              // Finance related docs.
              case this.hrdcData.stagePriority.creditNote:
              case this.hrdcData.stagePriority.creditNoteGenerated:
                this.financeAttachment.push(tmpAttachmentObj);
                break;

              case this.hrdcData.stagePriority.refundEmailArToAp:
                attachmentDuplicateCheck = this.financeAttachment.find(
                  (item) => {
                    return (
                      item.showView &&
                      item.fileStageId == tmpAttachmentObj.fileStageId
                    );
                  }
                );

                if (attachmentDuplicateCheck) {
                  this.financeAttachment = this.financeAttachment.filter(
                    (item) =>
                      item.attachmentUuid !==
                      attachmentDuplicateCheck.attachmentUuid
                  );
                }

                this.financeAttachment.push(tmpAttachmentObj);
                break;

              // Others related docs.
              default:
                this.othersAttachment.push(tmpAttachmentObj);
                break;
            }
          }
        }

        // Add Refund email.
        if (
          this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId] >=
            this.hrdcData.stageSlug.refundEmailSubmission &&
          this.applicationDetails.paymentChannel !== "Claim Basis"
        ) {
          const refundEmailObj = {
            fileName: "Refund Email",
            fileNameSlug: null,
            fileUrl: null,
            applicationUuid: this.$route.query._ausk,
            fileStageId: this.hrdcData.stagePriority.refundEmailSubmission,
            azureStorage: true,
            showView: false,
            showDownload: false,
            showEmail: true,
            downloadFileName: null,
            fileExtension: "email",
          };

          this.attachmentDetails.push(refundEmailObj);
          this.taxpodAttachment.push(refundEmailObj);
        }

        // Add refund email ar to ap.
        if (
          this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId] >=
            this.hrdcData.stageSlug.refundEmailArToAp &&
          this.applicationDetails.paymentChannel !== "Claim Basis"
        ) {
          const refundEmailArToApObj = {
            fileName: "Refund Email AR to AP",
            fileNameSlug: null,
            fileUrl: null,
            applicationUuid: this.$route.query._ausk,
            fileStageId: this.hrdcData.stagePriority.refundEmailArToAp,
            azureStorage: true,
            showView: false,
            showDownload: false,
            showEmail: true,
            downloadFileName: null,
            fileExtension: "email",
          };

          this.attachmentDetails.push(refundEmailArToApObj);
          this.financeAttachment.push(refundEmailArToApObj);
        }

        if (lastSentEmailIndex) {
          // Process flyer attachment name.
          let pricing = this.applicationDetails.oriTotalPrice;
          let pricingList = this.hrdcData.staticTaxpodPricing;
          let defaultPrice = pricingList[0];
          let flyerPricing = this.hrdcData.staticTaxpodFlyers;
          let flyer = flyerPricing.find((item) => item.price == pricing);
          if (!pricingList.includes(pricing)) {
            pricing = defaultPrice;
          }

          let fileName = "taxPOD Masterclass Flyer";
          let fileUrl = `assets/storage/Flyer-${pricing}.pdf`;
          if (flyer) {
            fileName += ` ${flyer.flyer}`;
          }

          // Convert flyers to blob data.
          const flyerObj = {
            fileName,
            fileUrl,
            azureStorage: false,
            showView: true,
            showDownload: true,
            fileNameSlug: "flyer",
            downloadFileName: fileName,
            fileExtension: "pdf",
          };

          this.attachmentDetails.splice(lastSentEmailIndex, 0, flyerObj);
          this.hrdcAttachment.splice(lastSentEmailIndex, 0, flyerObj);
        }

        // Categorize data into each diff module.
        const modulesToUpdate = ["HRDC", "taxPOD", "Finance", "Others"];
        const arraysToUpdate = [
          this.hrdcAttachment,
          this.taxpodAttachment,
          this.financeAttachment,
          this.othersAttachment,
        ];

        modulesToUpdate.forEach((name, index) => {
          const itemIndex = this.attachmentTreeview.findIndex(
            (item) => item.name === name
          );
          if (itemIndex !== -1) {
            this.attachmentTreeview[itemIndex].children = arraysToUpdate[index];

            if (this.attachmentTreeview[itemIndex].children.length === 0) {
              this.attachmentTreeview.splice(itemIndex, 1);
            }
          }
        });

        // Other Details.
        const generalRemarks = data.generalRemarks;
        this.otherDetails.generalRemarks = generalRemarks;

        // Flag Remarks Details.
        this.flagRemarks = data.flagRemarks;
        this.otherDetails.flagRemarks = this.flagRemarks;

        // Get Refund Email Remarks.
        const refundRemarks = data.refundRemarks;
        if (refundRemarks) {
          this.otherDetails.remarks.push({
            id: null,
            stageName: "Refund Email Remarks",
            remarks: refundRemarks,
            remarkBy: null,
          });
        }

        // Get logs, remarks details.
        this.apiGetApplicationStages.setCallbackCompleted((response) => {
          try {
            if (!response.status) {
              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "error",
                timeout: 2000,
              });
            } else {
              let emailStageCount = {};
              const { data } = response;

              if (generalRemarks) {
                const lastUpdateGeneralRemarks = data.filter(
                  (item) =>
                    item.stageId ===
                    this.hrdcData.stagePriority.generalInfoRemarks
                );

                if (lastUpdateGeneralRemarks.length > 0) {
                  const lastElement = lastUpdateGeneralRemarks.pop();
                  this.otherDetails.remarks.push({
                    id: "GENERAL_REMARKS",
                    stageName: "General Remarks",
                    remarks: generalRemarks,
                    remarkBy: lastElement.performedBy,
                  });
                }
              }

              if (this.flagRemarks) {
                const lastUpdateFlagRemarks = data.filter(
                  (item) =>
                    item.stageId === this.hrdcData.stagePriority.normalFlag
                );

                if (lastUpdateFlagRemarks.length > 0) {
                  const lastFlagElement = lastUpdateFlagRemarks.pop();
                  this.otherDetails.remarks.push({
                    id: "FLAG_REMARKS",
                    stageName: "Flag Remarks",
                    remarks: this.flagRemarks,
                    remarkBy: lastFlagElement.performedBy,
                  });
                }
              }

              // Do not display refund remarks.
              const refundStage = [
                this.hrdcData.stagePriority.refundEmailSubmission,
                this.hrdcData.stagePriority.refundEmailApproval1,
                this.hrdcData.stagePriority.refundEmailApproval2,
              ];

              // Differentiate application logs & general logs.
              const generalLogStages = [
                this.hrdcData.stagePriority.generalInfoRemarks,
                this.hrdcData.stagePriority.normalFlag,
                this.hrdcData.stagePriority.normalUnflag,
              ];

              // Normal remarks from each forms.
              for (const item of data) {
                let stageName = item.stageName;

                // No need to display in log array.
                const undisplayStageLog = [
                  "Client Revert JD14 Form",
                  "Client Revert T3 Form",
                  "Quotation Date Scheduled",
                  "Sent JD14 Form To Client",
                  "Sent T3 Form To Client",
                  "Manual Invoice To HRDC",
                ];

                if (!undisplayStageLog.includes(stageName)) {
                  if (this.stageCountable.includes(item.stageId)) {
                    if (!emailStageCount[item.stageName]) {
                      emailStageCount[item.stageName] = 1;
                    } else {
                      emailStageCount[item.stageName]++;
                    }
                    stageName = `${stageName} - (Sent ${
                      emailStageCount[item.stageName]
                    } time)`;
                  }

                  if (generalLogStages.includes(item.stageId)) {
                    this.generalLogs.push({
                      id: item.id,
                      userUuid: item.performedBy ?? "-",
                      stageName: this.changeStageName(item.stageId, stageName),
                      createdDate: moment(item.timestampCreated).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                      updatedDate: moment(item.timestampUpdated).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                      startDate:
                        item.endDate !== null
                          ? moment(item.startDate).format("Y-MM-DD")
                          : "-",
                      endDate:
                        item.endDate !== null
                          ? moment(item.endDate).format("Y-MM-DD")
                          : "-",
                      scheduledDate: this.getScheduledDate(
                        item.stageId,
                        item.startDate,
                        item.endDate
                      ),
                    });
                  } else {
                    this.applicationLogs.push({
                      id: item.id,
                      userUuid: item.performedBy ?? "-",
                      stageName: this.changeStageName(item.stageId, stageName),
                      createdDate: moment(item.timestampCreated).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                      updatedDate: moment(item.timestampUpdated).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                      startDate:
                        item.endDate !== null
                          ? moment(item.startDate).format("Y-MM-DD")
                          : "-",
                      endDate:
                        item.endDate !== null
                          ? moment(item.endDate).format("Y-MM-DD")
                          : "-",
                      scheduledDate: this.getScheduledDate(
                        item.stageId,
                        item.startDate,
                        item.endDate
                      ),
                    });
                  }
                }

                if (item.remarks) {
                  if (!refundStage.includes(item.stageId)) {
                    let stageName = this.renameRemarkStageName(item.stageName);
                    if (!undisplayStageLog.includes(stageName)) {
                      this.otherDetails.remarks.push({
                        id: item.id,
                        stageName,
                        remarks: item.remarks,
                        remarkBy: item.performedBy,
                      });
                    }
                  }
                }
              }
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });
        this.apiGetApplicationStages.fetch();

        this.filterStatusByStagesAndConditions(
          this.hrdcData.stageIndex[this.applicationDetails.stageId]
        );

        const completeStage = [
          this.hrdcData.stagePriority.applicationSubmitted,
          this.hrdcData.stagePriority.emailPaymentAdviceToClient,
          this.hrdcData.stagePriority.taxpodAccountActivation,
          this.hrdcData.stagePriority.hrdcClaimCompleted,
        ];

        this.nextActionDisplay = !completeStage.includes(
          this.applicationDetails.stageId
        )
          ? true
          : false;

        // Set if payment not yet made, then add enrollment details to action lists.
        if (!data.isPaymentMade) {
          this.actions.push({
            title: "Enrollment Detail",
            route: "EnrollmentDetail",
          });
        }

        if (data.isPaymentMade) {
          this.actions.push(
            {
              title: "Refund Email Submission",
              route: "RefundEmailSubmission",
            },
            {
              title: "Refund Email Approval 1",
              route: "RefundEmailApproval1",
            },
            {
              title: "Refund Email Approval 2",
              route: "RefundEmailApproval2",
            },
            {
              title: "Refund Email AR to AP",
              route: "RefundEmailArToAp",
            },
            {
              title: "Finance Refund to Client",
              route: "FinanceRefundToClient",
            },
            {
              title: "Email Payment Advice to Client",
              route: "EmailPaymentAdviceToClient",
            }
          );
        }

        // Append delete application function.
        this.actions.push({
          title: "Delete Application",
          route: "DeleteApplication",
        });
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationDetails.fetch();
  },
  methods: {
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      }
    },
    showErrorMessage(response) {
      this.errorField.updateStageId = null;
      for (const key in response.data) {
        this.errorField[key] = response.data[key];
      }
    },
    updateApplicationStatus() {
      // Not allow to approve application if haven't assign company from Vision.
      if (this.paymentMadeApplication) {
        return this.$store.dispatch("showMessage", {
          message: "Please assign 1 company from Vision in Edit Details",
          messageType: "error",
          timeout: 2000,
        });
      }

      const fd = new FormData();
      fd.append("stageId", this.applicationDetails.updateStageId);
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("userUuid", this.auth.uuid);
      fd.append("csUuid", this.customerServiceDetails.customerServiceSelect);

      fetch(
        `${
          this.$service[this.service.key]
        }/v1/en/console/application/update-stages`,
        {
          method: "post",
          body: fd,
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.data.token,
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          try {
            if (!response.status) {
              if (response.statusCode == 500) {
                this.$store.dispatch("showMessage", {
                  message: response.message,
                  messageType: "error",
                  timeout: 2000,
                });
                return;
              }
              this.showErrorMessage(response);
            } else {
              this.updateStatusDialog = !this.updateStatusDialog;
              this.$store.commit("assignDeveloperData", {
                paginationPageNo: this.$route.query.currentPageNo,
              });

              this.$router.push({
                name: "HrdcApplication",
                query: {
                  viewId: this.$store.state.hrdc.data.viewId,
                },
              });
              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "success",
                timeout: 2000,
              });
            }
          } catch (err) {
            console.log(err);
          }
        });
    },
    modalUpdateApplicationStatus() {
      this.applicationDetails.updateStageId = this.updateStatusBtn;
      this.dialog = false;
      this.updateApplicationStatus();
    },
    filterStatusByStagesAndConditions(stagePriority) {
      const allStageSlug = this.hrdcData.stageSlug;
      const allStagePriority = this.hrdcData.stagePriority;
      for (const stage in allStageSlug) {
        switch (stagePriority) {
          case allStageSlug.applicationSubmitted:
            // Stage=only allowed to approved this application
            if (stage == "applicationApproved") {
              let stageTmp = {
                text: this.camelCaseToNormalCase(stage),
                value: allStagePriority[stage],
              };

              this.updateStatusBtn = allStagePriority[stage];
              this.updateStatusText = stageTmp.text;
              this.rolesAllowed = this.checkRoleAllows(
                "hrdc-taxpod-application-approval-admin"
              );
              if (this.rolesAllowed) {
                this.alertColor = "#E57373";
                this.stages.push(stageTmp);
              }
            }
            break;
        }
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    async previewOrDownloadAttachment(item, action) {
      // Check if is a email, then open email dialog.
      if (item.showEmail) {
        this.getEmailContent(item);
        return;
      }

      this.atchLoadingDialog = !this.atchLoadingDialog;

      let fileUrl = item.fileUrl;
      let azureStorage = item.azureStorage;
      let downloadFileName = item.downloadFileName;
      let fileNameSlug = item.fileNameSlug;

      // Directly open new tab if detected is a web url (etc: https://...)
      const validUrl = this.isValidURL(fileUrl);
      if (validUrl) {
        this.atchLoadingDialog = !this.atchLoadingDialog;

        if (action == "preview") {
          window.open(fileUrl, "_blank");
          return;
        } else {
          window.open(fileUrl, "_blank");
          this.$store.dispatch("showMessage", {
            message:
              "Sorry, due to the privacy issue, this attachment only can be view",
            messageType: "info",
            timeout: 5000,
          });

          return;
        }
      }

      let attachmentExt = fileUrl.split(".")[1].toLowerCase();
      if (item.fileNameSlug) {
        attachmentExt = fileUrl.split(".").pop();
      }

      fileUrl = fileUrl.replace(/\.[^.]+$/, (match) => match.toLowerCase());

      let responseType = "blob";
      let isImage = false;
      let blobType = `application/${attachmentExt}`;
      let streamUrl = this.apiDownloadOrPreviewAttachment.url;
      let downloadAttachmentName = `${this.companyDetails.companyName}.${attachmentExt}`;
      if (downloadFileName) {
        switch (fileNameSlug) {
          case "CLIENT_REVERT_JD14_FORM":
            downloadAttachmentName = `${this.companyDetails.companyNameOnly}_${downloadFileName}.${attachmentExt}`;
            break;
          case "CLIENT_REVERT_T3_FORM":
            downloadAttachmentName = `${this.companyDetails.companyNameOnly}_${downloadFileName}.${attachmentExt}`;
            break;
          case "flyer":
            downloadAttachmentName = `${downloadFileName}.${attachmentExt}`;
            break;
          case "MANUAL_INVOICE_TO_HRDC":
            downloadAttachmentName = `${downloadFileName}.${attachmentExt}`;
            break;
        }
      }

      if (this.isImage(fileUrl)) {
        responseType = "arraybuffer";
        isImage = true;
        blobType = `image/${attachmentExt.toLowerCase()}`;
      }

      // If is flyer, then directly retrieve from local assets.
      if (!azureStorage) {
        streamUrl = this.apiGetAttachmentBlob.url;
      }

      await this.$axios
        .get(streamUrl, {
          responseType,
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
            isImage,
          },
        })
        .then((response) => {
          if (action == "preview") {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: blobType })
            );

            window.open(blobUrl, "_blank");
          } else if (action == "download") {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", downloadAttachmentName);
            document.body.appendChild(link);
            link.click();
          }

          this.atchLoadingDialog = !this.atchLoadingDialog;
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
          this.atchLoadingDialog = !this.atchLoadingDialog;
        });
    },
    getEmailContent(attachment) {
      try {
        this.loadingDialog = !this.loadingDialog;
        this.emailModalDetails.emailModalHeader = "Email Details";
        this.apiGetEmailDetails.setParams({
          applicationUuid: attachment.applicationUuid,
          stageId: attachment.fileStageId,
        });
        this.apiGetEmailDetails.setCallbackCompleted((response) => {
          if (response.status) {
            const applicationEmail = response.data;
            const applicationEmailDetails =
              applicationEmail.ApplicationEmailDetail;

            const toRecipients = [];
            const ccRecipients = [];
            this.emailModalDetails.emailModalSubject =
              applicationEmail.emailSubject;
            this.emailModalDetails.emailModalContent =
              applicationEmail.emailContent;
            for (const emailDetail of applicationEmailDetails) {
              if (emailDetail.recipientType == "TO") {
                toRecipients.push(emailDetail.recipientEmail);
              }
              if (emailDetail.recipientType == "CC") {
                ccRecipients.push(emailDetail.recipientEmail);
              }
            }

            this.emailModalDetails.emailModalToRecipient = toRecipients;
            this.emailModalDetails.emailModalCcRecipient = ccRecipients;
          }

          this.emailDialog = true;
        });
        this.apiGetEmailDetails.fetch();
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingDialog = !this.loadingDialog;
      }
    },
    renameRemarkStageName(stageName) {
      let stage = stageName;
      switch (stageName) {
        case "Grant Approval No Submitted":
          stage = "Grant Approval No.";
          break;
        case "JD14 T3 Form Submitted":
          stage = "JD14 & T3 Form";
          break;
      }

      return stage;
    },
    checkRoleAllows(checkRole) {
      return this.auth.roles.some((role) => role.slug.includes(checkRole));
    },
    // applicationApproved -> Application Approved
    camelCaseToNormalCase(key) {
      // Split camelCase to words
      const words = key.split(/(?=[A-Z])/);
      // Capitalize the first word and join with spaces
      return words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    // APPLICATION_APPROVED -> Application Approved
    convertToTitleCase(input) {
      const words = input.split("_");
      const titleCaseWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      return titleCaseWords.join(" ");
    },
    // Application Approved -> APPLICATION_APPROVED
    titleCaseToUppercase(key) {
      if (key !== null) {
        return key.toUpperCase().replace(/ /g, "_");
      }
    },
    checkEmailContent(fileStageId) {
      let fileStagePriority = this.hrdcData.stageIndex[fileStageId];
      let viewEmail = false;
      switch (fileStagePriority) {
        case this.hrdcData.stageSlug.sentConfirmationEmailToClient:
          viewEmail = true;
          break;
        case this.hrdcData.stageSlug.refundEmailSubmission:
          viewEmail = true;
          break;
      }
      return viewEmail;
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item.trim();
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
        timeout: 2000,
      });
    },
    isValidURL(str) {
      try {
        new URL(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    redirectForm(action) {
      const params = {
        applicationUuid: this.$route.query._ausk,
        stageId: this.$store.state.hrdc.data.stageId,
        firstNameAccEnrollment: this.enrollmentDetails.firstNameAccEnrollment,
        lastNameAccEnrollment: this.enrollmentDetails.lastNameAccEnrollment,
        contactNoAccEnrollment: this.enrollmentDetails.contactNoAccEnrollment,
        emailAccEnrollment: this.enrollmentDetails.emailAccEnrollment,
        viewId: this.$store.state.hrdc.data.viewId,
      };

      this.$store.commit("assignHrdcData", params);

      // Delete application.
      if (action.route == "DeleteApplication") {
        this.deleteApplicationDialog = true;
        return;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: action.route,
        params: {
          backTo: "ViewHrdcApplication",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    deleteApplication() {
      this.deleteApplicationDialog = false;
      this.apiDeleteApplication.setParams({
        applicationUuid: this.$route.query._ausk,
        companyNameFilledByApplicant: this.companyDetails.oriCompanyName,
      });
      this.apiDeleteApplication.setCallbackCompleted((response) => {
        try {
          if (response) {
            if (response.status) {
              this.$store.commit("assignDeveloperData", {
                paginationPageNo: this.$route.query.currentPageNo,
              });

              this.$router.push({
                name: "HrdcApplication",
                query: {
                  viewId: this.$store.state.hrdc.data.viewId,
                },
              });
            }
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          } else {
            this.$store.dispatch("showMessage", {
              message: "Unable to delete application!",
              messageType: "error",
              timeout: 2000,
            });
          }
        } catch (err) {
          this.$store.dispatch("showMessage", {
            message: "Unable to delete application!",
            messageType: "error",
            timeout: 2000,
          });
        }
        this.deleteApplicationDialog = false;
      });
      this.apiDeleteApplication.fetch();
    },
    restoreTrashApplication() {
      this.apiRestoreApplication.setParams({
        applicationUuid: this.$route.query._ausk,
      });
      this.apiRestoreApplication.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (status) {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.$router.push({
              name: "ViewHrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
                _ausk: this.$route.query._ausk,
              },
            });
          }
        } catch (err) {
          console.log(err);
          this.$store.dispatch("showMessage", {
            message: "Unable to delete application!",
            messageType: "error",
            timeout: 2000,
          });
        }
      });
      this.apiRestoreApplication.fetch();
    },
    goToBackApPage() {
      this.$router.push({
        name: "RefundEmailApToCimb",
        params: { modelKey: "refundApToCimb" },
        query: {
          viewId: "all",
        },
      });
    },
    goToBack() {
      // read url query
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: "HrdcApplication",
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
        },
      });
    },
    nextAction() {
      const stageId = this.applicationDetails.stageId;
      const applicationUuid = this.$route.query._ausk;
      const firstNameAccEnrollment =
        this.enrollmentDetails.firstNameAccEnrollment;
      const lastNameAccEnrollment =
        this.enrollmentDetails.lastNameAccEnrollment;
      const contactNoAccEnrollment =
        this.enrollmentDetails.contactNoAccEnrollment;
      const emailAccEnrollment = this.enrollmentDetails.emailAccEnrollment;

      // Handle for duplicate application cases.
      switch (stageId) {
        case this.hrdcData.stagePriority.applicationDuplicated:
          this.proceedDuplicateApplicationDialog = true;
          return;
      }

      let route = this.nextFormRedirect(
        stageId,
        this.applicationDetails.paymentChannel
      );

      let params = {
        applicationUuid,
        stageId,
        firstNameAccEnrollment,
        lastNameAccEnrollment,
        contactNoAccEnrollment,
        emailAccEnrollment,
        viewId: this.$store.state.hrdc.data.viewId,
      };

      if (route) {
        this.$store.commit("assignHrdcData", params);

        this.$router.push({
          name: route,
          params: { backTo: "ViewHrdcApplication" },
          query: {
            _ausk: this.$route.query._ausk,
            viewId: "all",
          },
        });
      }
    },
    nextFormRedirect(stageId, paymentChannel) {
      let route = null;
      switch (stageId) {
        case this.hrdcData.stagePriority.applicationSubmitted:
          route = "ViewHrdcApplication";
          break;

        case this.hrdcData.stagePriority.applicationApproved:
          route = "SendHrdcEmailToClient";
          break;

        case this.hrdcData.stagePriority.sentConfirmationEmailToClient:
        case this.hrdcData.stagePriority.clientRevertFormIncomplete:
        case this.hrdcData.stagePriority.grantApproved:
        case this.hrdcData.stagePriority.formsCompleted:
          route = "ClientRevertForm";
          break;

        case this.hrdcData.stagePriority.clientRevertT3Form:
          route = "ManualInvoiceToHrdc";
          break;

        case this.hrdcData.stagePriority.manualInvoiceToHrdc:
          route = "ClaimSubmitToHrdc";
          break;

        case this.hrdcData.stagePriority.submittedClaimToHrdc:
          route = "ClaimApprovedFromHrdc";
          break;

        case this.hrdcData.stagePriority.claimApprovedFromHrdc:
          route = "PaymentReceivedFromHrdc";
          break;

        case this.hrdcData.stagePriority.paymentReceivedFromHrdc:
          if (paymentChannel == "Claim Basis") {
            route = "EnrollmentDetail";
          }
          break;

        case this.hrdcData.stagePriority.creditNoteRequested:
          route = "CreditNoteDocumented";
          break;

        case this.hrdcData.stagePriority.creditNoteDocumented:
        case this.hrdcData.stagePriority.creditNoteGenerated:
          route = "RefundEmailSubmission";
          break;

        case this.hrdcData.stagePriority.refundEmailSubmission:
          route = "RefundEmailApproval1";
          break;

        case this.hrdcData.stagePriority.refundEmailApproval1:
          route = "RefundEmailApproval2";
          break;

        case this.hrdcData.stagePriority.refundEmailApproval2:
        case this.hrdcData.stagePriority.refundEmailApToCimbRejected:
          route = "RefundEmailArToAp";
          break;

        case this.hrdcData.stagePriority.refundEmailArToAp:
          route = "RefundEmailApToCimb";
          break;

        case this.hrdcData.stagePriority.apSubmitRefundToCimb:
          route = "FinanceRefundToClient";
          break;

        case this.hrdcData.stagePriority.refundFailed:
          route = "FinanceRefundToClient";
          break;

        case this.hrdcData.stagePriority.financeRefundToClient:
          route = "EmailPaymentAdviceToClient";
          break;

        default:
          route = "ViewHrdcApplication";
          break;
      }

      return route;
    },
    getScheduledDate(stageId, startDate = null, endDate = null) {
      let date = null;

      switch (stageId) {
        case this.hrdcData.stagePriority.subscriptionDateScheduled:
        case this.hrdcData.stagePriority.refundEmailUpdated:
          date = `${moment(startDate).format("Y-MM-DD")} - ${moment(
            endDate
          ).format("Y-MM-DD")}`;
          break;
        default:
          date = `${moment(startDate).format("Y-MM-DD")}`;
          break;
      }

      return date;
    },
    changeStageName(stageId, stageName) {
      switch (stageId) {
        case this.hrdcData.stagePriority.invoiceDateScheduled:
          stageName = "Manual Invoice To HRDC";
          break;

        case this.hrdcData.stagePriority.grantApprovalNoSubmitted:
          stageName = "Grant Approval Number Submitted";
          break;

        default:
          break;
      }

      return stageName;
    },
    pendingAction() {
      let pending = null;

      // Check is this is a deleted application.
      if (this.applicationDetails.isApplicationDeleted) {
        return "Application Deleted";
      }

      if (this.hrdcData.stagePriority) {
        //console.log('stage id',this.hrdcData.stagePriority);
        switch (this.applicationDetails.stageId) {
          case this.hrdcData.stagePriority.applicationDuplicated:
            pending = "Application Duplicated";
            break;
          case this.hrdcData.stagePriority.applicationSubmitted:
            pending =
              "Pending Approval Application - Application Approval Admin";
            break;
          case this.hrdcData.stagePriority.applicationApproved:
            pending =
              "Pending Send HRDC Confirmation Email to Client - HRDC Admin";
            break;
          case this.hrdcData.stagePriority.sentConfirmationEmailToClient:
          case this.hrdcData.stagePriority.grantApproved:
          case this.hrdcData.stagePriority.formsCompleted:
          case this.hrdcData.stagePriority.clientRevertFormIncomplete:
            pending = "Pending Grant Approval & Revert Form - HRDC Admin";
            break;
          case this.hrdcData.stagePriority.clientRevertT3Form:
            pending = "Pending Manual Invoice to HRDC - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.manualInvoiceToHrdc:
            pending = "Pending Submit Claim to HRDC - HRDC Admin";
            break;
          case this.hrdcData.stagePriority.submittedClaimToHrdc:
            pending = "Pending Claim Approve from HRDC - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.claimApprovedFromHrdc:
            pending = "Pending Receive Payment from HRDC - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.paymentReceivedFromHrdc:
            pending = "Pending Generate CN - taxPOD Admin";
            if (this.applicationDetails.paymentChannel == "Claim Basis") {
              pending = "Pending Account Enrollment - taxPOD Admin";
            }
            break;
          case this.hrdcData.stagePriority.creditNoteRequested:
            pending = "Pending CN Documented - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.creditNoteDocumented:
          case this.hrdcData.stagePriority.creditNoteGenerated:
            pending = "Pending Refund Email Submission - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.refundEmailSubmission:
            pending =
              "Pending Refund Email Approval 1 - taxPOD Approval 1 Admin";
            break;
          case this.hrdcData.stagePriority.refundEmailApproval1:
            pending =
              "Pending Refund Email Approval 2 - taxPOD Approval 2 Admin";
            break;
          case this.hrdcData.stagePriority.refundEmailApproval2:
          case this.hrdcData.stagePriority.refundEmailApToCimbRejected:
            pending = "Pending Refund Email AR to AP - Finance AR Admin";
            break;
          case this.hrdcData.stagePriority.refundEmailArToAp:
            pending = "Pending Refund AP to CIMB - Finance AP Admin";
            break;
          case this.hrdcData.stagePriority.apSubmitRefundToCimb:
          case this.hrdcData.stagePriority.refundFailed:
            pending = "Pending Finance Refund to Client - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.financeRefundToClient:
            pending = "Pending Email Payment Advice to Client - taxPOD Admin";
            break;
          case this.hrdcData.stagePriority.emailPaymentAdviceToClient:
          case this.hrdcData.stagePriority.taxpodAccountActivation:
          case this.hrdcData.stagePriority.hrdcClaimCompleted:
            pending = "Application Completed";
            break;
        }
      }

      return pending;
    },
    castStageName(data) {
      let stageName = null;

      // get jd14, t3, grant status.
      let jd14T3Icon = "mdi mdi-close-circle";
      let grantIcon = "mdi mdi-close-circle";
      let jd14T3Color = "red--text";
      let grantColor = "red--text";

      let jd14Status = data.applicationLog.find(
        (item) =>
          item.stageId == this.hrdcData.stagePriority.clientRevertJd14Form
      );
      let t3Status = data.applicationLog.find(
        (item) => item.stageId == this.hrdcData.stagePriority.clientRevertT3Form
      );

      switch (data.stageNameSlug) {
        case "grantApproved":
          if (jd14Status || t3Status) {
            jd14T3Icon = "mdi mdi-alert-circle";
            jd14T3Color = "orange--text";
          }

          grantIcon = "mdi mdi-check-circle";
          grantColor = "green--text";

          stageName = this.generateGrantFormIssueStageName({
            jd14T3Icon,
            jd14T3Color,
            grantIcon,
            grantColor,
          });

          break;

        case "formsCompleted":
          jd14T3Icon = "mdi mdi-check-circle";
          jd14T3Color = "green--text";

          if (data.grantApprovalNo) {
            grantIcon = "mdi mdi-alert-circle";
            grantColor = "orange--text";
          }

          stageName = this.generateGrantFormIssueStageName({
            jd14T3Icon,
            jd14T3Color,
            grantIcon,
            grantColor,
          });

          break;

        case "clientRevertT3Form":
          jd14T3Icon = "mdi mdi-check-circle";
          jd14T3Color = "green--text";
          grantIcon = "mdi mdi-check-circle";
          grantColor = "green--text";

          stageName = this.generateGrantFormIssueStageName({
            jd14T3Icon,
            jd14T3Color,
            grantIcon,
            grantColor,
          });

          break;

        case "clientRevertFormIncomplete":
        case "sentConfirmationEmailToClient":
          if (jd14Status || t3Status) {
            jd14T3Icon = "mdi mdi-alert-circle";
            jd14T3Color = "orange--text";
          }

          if (data.grantApprovalNo) {
            grantIcon = "mdi mdi-alert-circle";
            grantColor = "orange--text";
          }

          stageName = this.generateGrantFormIssueStageName({
            jd14T3Icon,
            jd14T3Color,
            grantIcon,
            grantColor,
          });

          break;

        default:
          stageName = `${data.stageName}`;
          break;
      }

      return stageName;
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    submitGeneralRemarks() {
      this.loadingDialogBar = !this.loadingDialogBar;

      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("referById", this.auth.uuid);
      fd.append("generalRemarks", this.otherDetails.generalRemarks);

      this.apiSubmitGeneralRemarks.setParams(fd);
      this.apiSubmitGeneralRemarks.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialogBar = !this.loadingDialogBar;
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            // Change the general remarks.
            const generalRemarkIndex = this.otherDetails.remarks.findIndex(
              (remark) => remark.id === "GENERAL_REMARKS"
            );

            if (this.otherDetails.generalRemarks) {
              if (generalRemarkIndex !== -1) {
                this.otherDetails.remarks[generalRemarkIndex].remarks =
                  this.otherDetails.generalRemarks;
              } else {
                this.otherDetails.remarks.push({
                  id: "GENERAL_REMARKS",
                  stageName: "General Remarks",
                  remarks: this.otherDetails.generalRemarks,
                  remarkBy: this.auth.name,
                });
              }
            } else {
              this.otherDetails.remarks.splice(generalRemarkIndex, 1);
            }

            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
            this.generalRemarksDialog = !this.generalRemarksDialog;
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmitGeneralRemarks.fetch();
    },
    openUpdateFlagModal() {
      const flagVal = this.applicationDetails.isFlag;
      let flagText = "Unflag";
      if (!flagVal) {
        flagText = "Flag";
      }

      // Add the flag text here.
      this.flagOrUnflagText = flagText;
      this.flagDialog = !this.flagDialog;
    },
    updateFlagRemarkOnly() {
      this.api.setMethod("POST");
      this.api.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/application/update-flag-remark-only`
      );
      this.api.setParams({
        applicationUuid: this.$route.query._ausk,
        flagRemarks: this.flagRemarks,
      });
      this.api.setCallbackCompleted((response) => {
        const { status, message } = response;

        if (status) {
          // Change the flag remarks.
          const flagRemarkIndex = this.otherDetails.remarks.findIndex(
            (remark) => remark.id === "FLAG_REMARKS"
          );

          if (this.flagRemarks && this.applicationDetails.isFlag) {
            if (flagRemarkIndex !== -1) {
              this.otherDetails.remarks[flagRemarkIndex].remarks =
                this.flagRemarks;
            } else {
              this.otherDetails.remarks.push({
                id: "FLAG_REMARKS",
                stageName: "Flag Remarks",
                remarks: this.flagRemarks,
                remarkBy: this.auth.name,
              });
            }
          } else {
            // Automatically removed flag remarks.
            this.flagRemarks = null;
            this.otherDetails.remarks.splice(flagRemarkIndex, 1);
          }

          this.$store.dispatch("showMessage", {
            message,
            messageType: "success",
            timeout: 2000,
          });

          this.flagDialog = !this.flagDialog;
          this.clearErrorMsg();
        }

        if (!status) {
          this.showErrorMessage(response);
        }
      });
      this.api.fetch();
    },
    updateFlagAndRemark() {
      this.api.setMethod("POST");
      this.api.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/application/update-flag-status`
      );
      this.api.setParams({
        userUuid: this.auth.uuid, // Need to record who flag the case.
        applicationUuid: this.$route.query._ausk,
        flagVal: this.applicationDetails.isFlag,
        flagRemarks: this.flagRemarks, // Add the remarks.
      });
      this.api.setCallbackCompleted((response) => {
        try {
          const { status, message } = response;
          if (status) {
            this.applicationDetails.isFlag = !this.applicationDetails.isFlag;

            // Change the flag remarks.
            const flagRemarkIndex = this.otherDetails.remarks.findIndex(
              (remark) => remark.id === "FLAG_REMARKS"
            );

            if (this.flagRemarks) {
              if (flagRemarkIndex !== -1) {
                this.otherDetails.remarks[flagRemarkIndex].remarks =
                  this.flagRemarks;
              } else {
                this.otherDetails.remarks.push({
                  id: "FLAG_REMARKS",
                  stageName: "Flag Remarks",
                  remarks: this.flagRemarks,
                  remarkBy: this.auth.name,
                });
              }
            }

            this.$store.dispatch("showMessage", {
              message,
              messageType: "success",
              timeout: 2000,
            });

            this.flagDialog = !this.flagDialog;
            this.clearErrorMsg();
          }

          if (!status) {
            this.showErrorMessage(response);
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.api.fetch();
    },
    closeUpdateFlagAndRemarkDialog() {
      this.flagDialog = !this.flagDialog;
      this.flagRemarks = null;
      this.clearErrorMsg();
    },
    getFileExtension(fileUrl) {
      const fileExt = fileUrl.split(".").pop();
      return fileExt;
    },
    showFileActionList(e, item) {
      e.preventDefault();
      for (let key in this.showAttachmentAction) {
        this.showAttachmentAction[key] = false;
      }

      this.attachmentPointingItem = null;

      this.attachmentX = e.clientX;
      this.attachmentY = e.clientY;
      this.$nextTick(() => {
        this.showAttachmentAction.menu = true;
        this.attachmentPointingItem = item;
        if (item.showView) {
          this.showAttachmentAction.view = true;
        }
        if (item.showDownload) {
          this.showAttachmentAction.download = true;
        }

        if (this.checkEmailContent(item.fileStageId)) {
          this.showAttachmentAction.email = true;
        }
      });
    },
    moveApplicationToSubmitted() {
      this.apiProceedDuplicateApplication.setCallbackCompleted((response) => {
        try {
          if (!response.status) {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "error",
              timeout: 2000,
            });
            return;
          }

          if (response.status) {
            this.proceedDuplicateApplicationDialog =
              !this.proceedDuplicateApplicationDialog;
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });

            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
            return;
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiProceedDuplicateApplication.fetch();
    },
    generateGrantFormIssueStageName(data) {
      return `<span>
        <i aria-hidden="true" class="v-icon notranslate ${data.jd14T3Icon} ${data.jd14T3Color} theme--light " style="cursor: pointer;"></i>
        JD14 & T3 Form │
        <i aria-hidden="true" class="v-icon notranslate ${data.grantIcon} ${data.grantColor} theme--light " style="cursor: pointer;"></i>
        Grant No.
      </span>`;
    },
  },
};
</script>

<style scope>
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab,
.v-tabs-bar .v-tab {
  margin-left: 0px !important;
}

#fab-speed-dial .v-speed-dial {
  position: fixed;
}

#fab-speed-dial .v-btn--floating {
  position: fixed;
}

.expansion-details-gradient {
  background-color: rgba(6, 48, 88, 1);
}

.v-btn {
  opacity: 1 !important;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.btn-wrap-text {
  color: black;
  height: auto !important;
  max-width: 100%;
  white-space: normal;
  &.v-btn:not(.v-btn--round).v-size--default {
    padding: 10px 16px;
  }
  .v-btn__content {
    flex: 1 0 fit-content;
  }
}

.v-expansion-panel-content__wrap {
  padding: 0 10px 16px;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0;
}
</style>
